<template>
  <b-modal
    id="modal-edit-transaction"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal"
  >
    <b-container class="px-0 py-0">
      <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line px-2 py-1 bg-white">
        <b-row>
          <b-col
            class="align-self-center"
            cols="3"
          >
            <div
              style="cursor: pointer;"
              @click="$bvModal.hide('modal-edit-transaction')"
            >
              <feather-icon
                size="24"
                class="text-dark fw-bold-800"
                icon="XIcon"
              />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Batal</span>
            </div>
          </b-col>
          <b-col
            class="align-self-center"
            cols="6"
          >
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              Edit Transaksi INV/XXVI/III/0012
            </h1>
          </b-col>
          <b-col
            class="align-self-center text-right"
            cols="3"
          >
            <b-button
              class="px-2 py-1 pt-1 border-8"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </header>
    </b-container>
    <b-container class="mt-5 pt-5">
      <b-row class="mt-3 justify-content-center">
        <b-col
          cols="6"
          md="4"
          lg="4"
        >
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="text-dark text-darken-7 fw-bold-700 mb-0">
              Total Pembelian <span class="ml-1">4</span>
            </h6>
            <b-button
              v-b-modal.modal-add-product
              class="border-8"
            >
              + Tambah Produk
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-2">
        <b-col
          cols="8"
          md="5"
          lg="5"
        >
          <b-container class="cart__product--container">
            <CardListProduct />
            <div class="floating__button">
              <AddDiscount />
            </div>
          </b-container>
          <ModalAddProduct />
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import {
  BContainer, BButton, BModal, BRow, BCol,
} from 'bootstrap-vue'

import ModalAddProduct from '@/components/Transaction/Modal/AddProduct.vue'
import CardListProduct from '@/components/Cashier/Cart/CartListProduct.vue'
import AddDiscount from '@/components/AddDiscount.vue'

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    BContainer,
    CardListProduct,
    ModalAddProduct,
    AddDiscount,
  },
  data() {
    return {
    }
  },
  mounted() {
    this.getActiveCart()
  },
  methods: {
    async getActiveCart() {
      await this.$store.dispatch('cart/getCart', {
        uuid: '',
        params: '',
      }).then(result => {
        const item = result.data.data
        this.$store.commit('cart/setResultCartActive', item)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

#modal-edit-transaction {
  .modal-dialog {
    max-width: 100%;
    margin: 0;

    .modal-content {
      background: #f6f7fa;
      height: 100vh;

      .modal-body {
        padding: 0;
        overflow-y: scroll;

        .bg-wave {
          background-image: url('../../..//assets/images/wave-bg.svg');
          background-repeat: no-repeat;
          background-size: cover;
        }

        button {
          &.bg-white {
            background-color: #FFFFFF !important;
            border: 1px solid $light--3 !important;
            color: $dark !important;
          }
        }

        label {
          font-size: 14px;
          color: $dark;
          font-weight: 600;
        }

        .vs__open-indicator {
          fill: rgba(60, 60, 60, 0.5);
        }

        .custom__input {
          height: 52px;
          background: #EFF1F5;
          border: 1px solid #E4E5EC;
          border-radius: 8px;
        }

        .vs__dropdown-toggle {
          height: 52px;
          background-color: #EFF1F5;
          border: 1px solid #E4E5EC;
          border-radius: 8px;
        }
        .floating__button {
          position: sticky;
          bottom: 0;
          width: 445px;
          @media screen and (min-width: 1280px) {
            position: sticky;
            width: 538px;
            bottom: 0;
          }
        }
      }
    }
  }
}
</style>
