var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transaction"},[_c('header-page',[_vm._v(" Transaksi ")]),_c('div',{staticClass:"transaction__filter--container py-1"},[_c('div',{staticClass:"row mx-0 justify-content-between w-100 flex-lg-row flex-column-reverse"},[_c('b-col',{attrs:{"cols":"12","md":"5","lg":"5"}},[_c('b-button-group',_vm._l((_vm.listFilter),function(item,index){return _c('b-button',{key:(item + "-" + index),staticClass:"filter--button",class:{ active: item.name == _vm.filters },attrs:{"id":("button__filter__" + index)},on:{"click":function($event){(_vm.status = item.value), (_vm.filters = item.name)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('b-col',{staticClass:"d-flex search__export justify-content-end",attrs:{"cols":"12","md":"7","lg":"7"}},[_c('div',{staticClass:"search__input"},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Cari nama pembeli atau nomor invoice"},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('FilterButton',{attrs:{"is_transaction":true,"get-data":_vm.getData},on:{"filterBase":_vm.getFilterBase}})],1)],1)]),_c('vue-good-table',{attrs:{"max-height":"80vh","columns":_vm.columns,"rows":_vm.items,"fixed-header":false,"search-options":{
      enabled: false,
    },"sort-options":{
      enabled: false,
    },"pagination-options":{
      enabled: false,
    }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('feather-icon',{staticClass:"align-middle",attrs:{"icon":"MoreVerticalIcon","size":"16"}})],1):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'customer.name')?_c('span',[_c('span',[_vm._v(_vm._s((props.row.customer && props.row.customer.name) || "-"))])]):(props.column.field === 'total')?_c('span',[_c('span',[_vm._v(_vm._s(_vm._f("formatAmount")(props.row.total)))])]):(props.column.field === 'payment_method')?_c('span',[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(props.row.payments.map(function (e) { return _vm.getLabel(e.payment_method); }).join(", ")))])]):(props.column.field === 'seller')?_c('span',[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s((props.row.seller && props.row.seller.name) || "-"))])]):(props.column.field === 'status')?_c('span',{staticClass:"text-capitalize"},[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status)}},[_vm._v(" "+_vm._s(props.row.status == "success" ? "Lunas" : props.row.status === "canceled" ? "Dibatalkan" : "Belum Lunas")+" ")])],1):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{staticClass:"d-flex",attrs:{"id":"dropdown-dropleft","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.goToDetail(props)}}},[_vm._v(" Lihat Invoice ")]),(
                props.row.status !== 'canceled' &&
                _vm.checkPermission('add retur')
              )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.returOrder(props.row.uuid)}}},[(_vm.isLoading)?_c('b-spinner'):_vm._e(),_vm._v(" Retur ")],1):_vm._e(),(
                props.row.status !== 'canceled' &&
                _vm.checkPermission('batalkan transaksi')
              )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteOrder(props.row.uuid)}}},[(_vm.isLoading)?_c('b-spinner'):_vm._e(),_vm._v(" Batalkan Transaksi ")],1):_vm._e()],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),(_vm.rows)?_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap bg-white border",staticStyle:{"padding":"0.8rem"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '15', '20']},model:{value:(_vm.filter.per_page),callback:function ($$v) {_vm.$set(_vm.filter, "per_page", $$v)},expression:"filter.per_page"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" Tampilkan "+_vm._s(_vm.rows.total > 0 ? _vm.filter.per_page * (_vm.rows.current_page - 1) + 1 : 0)+" ke "+_vm._s(_vm.rows.total > 0 ? Math.min(_vm.filter.per_page * _vm.rows.current_page, _vm.rows.total) : 0)+" dari "+_vm._s(_vm.rows.total)+" transaksi. ")])],1),_c('div',[_c('b-row',{staticClass:"mx-0 align-items-center"},[_c('b-col',[_c('pagination',{staticClass:"mb-0",attrs:{"data":_vm.rows,"limit":4,"align":"right"},on:{"pagination-change-page":_vm.getData}})],1)],1)],1)]):_vm._e(),_c('ModalRetur',{attrs:{"is_edit":false,"returType":'order',"results":_vm.returData,"getData":_vm.getData}}),_c('ModalEditTransaction')],1)}
var staticRenderFns = []

export { render, staticRenderFns }